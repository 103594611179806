import sdkConfig from "@/app/(public)/conf";
import Casdoor from "casdoor-js-sdk";

let casdoorInstance: any;

export function getCasdoorInstance() {
	if (!casdoorInstance) {
		casdoorInstance = new Casdoor(sdkConfig);
	}
	return casdoorInstance;
}
